const tab = {
  home: 'Home',
  find: 'Find',
  position: 'Position',
  collect: 'Collect',
  my: 'Mine'
}
const index = {
  welcome: 'Welcome',
  TNkkrich: 'TNkkrich',
  notice: 'The influence of NFT technology, Global Digital Asset Policy, NFT news events, NFT transaction order types and steps',
  PreSale: 'Pre-sale',
  TransactionList: 'Transaction List',
  num: 'Quantity',
  minPrice: 'Minimum Repurchase Price',
  nftDetail: 'NFT Details',
  Countdown: 'Countdown',
  nftInfo: 'NFT Information',
  publisherInfo: 'Publisher Information',
  features: 'Features',
  desc: 'Description',
  prePrice: 'Pre-sale Price',
  RepurchasePrice: 'Repurchase Price',
  PurchaseType: 'Purchase Type',
  oerderNow: 'Order Now',
  myCollect: 'My Favorites',
  myCollect1: 'My Fragments',
  Synthesis: 'Synthesis',
  hold: 'Hold',
  Introduction: 'Introduction',
  ViewTheAuthor: 'View the Author'
}

const find = {
  search: 'Search what you want',
  myCollect: 'My Favorites',
  myCollect_desc: 'Fragmented top NFT art exhibitions are all in TNFT',
  NFTsp: 'NFT Fragments',
  NFTsp_desc: 'Distribute the NFT you hold into shards',
  BecomeAmerchant: 'Become an Issuer',
  BecomeAmerchant_desc: 'Become an issuer and you can issue your own NFT',
  Announcement_headlines: 'Announcement Headlines',
  About_NFT: 'About NFT',
  Creation_Center: 'Creation Center',
  Synthesis_center: 'Synthesis Center',
  issure: 'Issuer'
}

const position = {
  Mypositions: 'My Positions',
  PositionMarketValue: 'Position Market Value (USDT)',
  TodayProfitAndLoss: 'Today\'s Profit and Loss',
  TotalProfitAndLoss: 'Total Profit and Loss',
  HoldingCost: 'Holding Cost',
  Number_of_fragments: 'Number of Fragments',
  PledgeShards: 'Pledge Shards',
  PledgeIncome: 'Pledge Income',
  TNFTRoyaltiesTrade: 'TNFT Royalties Trade',
  QuickBuy: 'Quick Buy',
  QuickSell: 'Quick Sell',
  History: 'History',
  name: 'Name',
  price: 'Price(USDT)',
  Market: 'Market Value(USDT)',
  Pledge: 'Pledge',
  TNFT: 'TNFT',
  BuyingPrice: 'Buying Price',
  SellingPrice: 'Selling Price',
  EstimatedBuybackPrice: 'Buyback Price',
  RepurchasePriceRatio: 'Repurchase Price Ratio',
  BuyQuantity: 'Buy Quantity',
  SellQuantity: 'Sell Quantity',
  placeholder1: 'Please enter buy quantity',
  placeholder2: 'Please enter sell quantity',
  submit: 'Submit',
  OTCwallet: 'OTC Wallet',
  SellToOTCbalance: 'Sell to OTC Balance',
  trxnumber:'TRX quantity',
  onSale:'order',
  sellingScope:'Selling scope',
  hangingOrderPrice:'Hanging order price',
  surplus:'surplus',
  withdraw:'withdraw',
  completed:'completed',
  withdrawn:'withdrawn',
  sellOut:'sell out',
  purchase:'purchase',
  comfirmTips:'Are you sure to cancel?',
  confirm:'confirm',
  cancel:'cancel',
  unlimited:'unlimited',
  acquisition:'acquisition'
}

const order = {
  orderHistory: 'Order History',
  all: 'All',
  inProgress: 'In Progress',
  expired: 'Expired',
  completed: 'Completed',
  orderID: 'Order ID',
  Payable: 'Payable',
  buy: 'Buy',
  viewNow: 'View Now',
  PaymentDetails: 'Payment Details',
  PurchaseType: 'Purchase Type',
  OrderTime: 'Order Time',
  State: 'State'
}

const login = {
  TNFT_account_login: 'TNFT Account Login',
  phone: 'Phone',
  mail: 'Mail',
  pwd: 'Password',
  placeholder1: 'Please enter your phone number',
  placeholder2: 'Please enter your email',
  placeholder3: 'Please enter a password',
  placeholder4: 'Please enter the verification code',
  placeholder5: 'Please enter the recommendation code',
  forgotPwd: 'Forgot Password?',
  forgotPwd1: 'Forgot Password',
  Reset_password: 'Reset Password',
  login: 'Log in',
  Dont_have_an_account_yet: 'Don\'t have an account yet?',
  Register_now: 'Sign up now',
  Register: 'Register',
  VerificationCode: 'Verification Code',
  ConfirmPassword: 'Confirm Password',
  getCode: 'Get Code',
  send: 'Sent',
  Referral_Code: 'Referral Code',
  by: 'By clicking register, I have read and agree to the',
  User_Agreement: 'User Agreement',
  submit: 'confirm'
}

const share = {
  My_invitation_code: 'My Invitation Code',
  desc: 'Scan to play TNFT, 0 transaction royalties, more referral royalties',
  save: 'Save QR Code'
}

const my = {
  Merchant: 'Merchant',
  OTCwallet: 'OTC Wallet',
  myCollect: 'My Favorites',
  myNFT: 'My NFT',
  Fragments: 'Fragments',
  OTCbalance: 'OTC Balance',
  Exchange: 'Exchange',
  Transfer_to_NFT: 'Transfer to NFT',
  Staking_TNFT: 'Staking TNFT',
  Account_binding: 'Account Binding',
  Invite_members: 'Invite Members',
  Contact_Customer_Service: 'Contact Customer Service',
  sign_out: 'Sign Out',

  Apply_to_be_a_merchant: 'Apply to be a Merchant',
  Company_Name: 'Company Name',
  p_e_Company_Name: 'Please enter company name',
  Country: 'Country',
  p_e_Country: 'Please enter country',
  Introduction: 'Introduction',
  p_e_Introduction: 'Please enter introduction',
  Business_license: 'Business License',
  Front_side_of_legal_person_certificate: 'Front Side of Legal Person Certificate',
  The_reverse_side_of_the_legal_person_certificate: 'Reverse Side of Legal Person Certificate',

  Apply_to_be_a_auth:'Apply for real name authentication',
  auth_realname:'Full name',
  auth_idcard:'ID number',
  bank_name:'Bank name',
  bank_account:'Account name',
  bank_card:'Bank account',
  Front_side_of_certificate:'Front of ID card',
  The_reverse_side_of_certificate:'Reverse of ID card',
  p_auth_realname: 'Please enter your name',
  p_auth_idcard: 'Please enter ID number number',
  p_bank_name:'Please enter the bank name',
  p_bank_account:'Please enter the bank account name',
  p_bank_card:'Please enter bank account number',

  Submit: 'Submit',
  reSubmit:'Resubmit',

  Edit_information: 'Edit Information',
  avatar: 'Avatar',
  Nickname: 'Real Name',
  Account: 'Account',
  Referral_code: 'Referral Code',
  Credit_score: 'Credit Score',
  SecuritySettings: 'Security Settings',
  Transaction_password: 'Transaction Password',
  New_Transaction_password: 'New Transaction Password',
  Login_password: 'Login Password',
  New_Login_password: 'New Login Password',
  ConfirmPassword: 'Confirm Password',
  p_e_Nickname: 'Please enter name',
  p_e_Password: 'Please enter password',

  Redeem_NFT: 'Redeem NFT',
  Synthesis: 'Synthesis',
  price: 'Price',
  Number_of_holdings: 'Number of Holdings',
  Price_trend: 'Price Trend',
  Buy: 'Buy',
  Sell: 'Sell',
  OTCtrading: 'OTC Trading',
  OTC_quick_transaction: 'OTC Quick Transaction',
  OTC_desc: 'Quick trading allows you to complete transactions at the best price in the current OTC market',
  I_want_to_pay: 'I want to pay',
  I_will_receive: 'I will receive',
  Proposed_price: 'Proposed Price',
  How_to_use_OTC: 'How to Use OTC',
  OTCdesc1: '1. Before purchasing digital currency, you first need to register an OTC account. Verify your account via the email address provided or the verification code received on your mobile phone, or register directly using your TNFT account.',
  OTCdesc2: '2. To ensure the security and compliance of transactions, please provide identity documents for identity verification. The platform will review the identity information you provide. This process may take some time, so please be patient.',
  OTCdesc3: '3. Once the account registration is completed and the identity verification is passed, you can start purchasing digital currencies. Enter the purchase page and select the digital currency you want to purchase. Enter the purchase quantity and confirm the transaction. Always check all details of your order before confirming the transaction. Once your order is matched and payment is completed, wait for the digital currency to be transferred to your OTC account.',

  OTCrecords: 'OTC Records',
  Unit_price: 'Unit Price',
  Quantity: 'Quantity',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  NFT_transfer_record: 'NFT Transfer Record',

  NFT_transfer_in: 'NFT Transfer In',
  Category: 'Category',
  address: 'Address',
  p_e_address: 'Please enter address',
  NFTname: 'NFT Name',
  Features: 'Features',
  Description: 'Description',
  p_e_name: 'Please enter name',
  p_e_Features: 'Please enter features',
  p_e_Description: 'Please enter description',
  Upload_NFT_file: 'Upload NFT File',
  Upload_transfer_certificate: 'Upload Transfer Certificate',
  copy_success: 'Copy Success',
  Confirm: 'Confirm',
  Pledge: 'Pledge',
  Hold: 'Hold',
  Pre_sale_price: 'Pre-sale Price',
  Time_day: 'Time (day)',
  Pledge_quantity: 'Pledge Quantity',
  p_e_quantity: 'Please enter quantity',
  Quantity_Available: 'Quantity Available',
  all: 'All',
  Fragment_reward_days: 'Fragment Reward (days)',
  Remark: 'Remark',
  Pledge_time: 'Pledge Time',
  Interest_accrual_time: 'Interest Accrual Time',
  Redemption_time: 'Redemption Time',
  Award: 'Award',
  Creation_time: 'Creation Time',
  End_time: 'End Time',
  Unpledge: 'Unpledge',
  Team_list: 'Team List',
  account: 'Account',
  zctime: 'Registration Time',
  Royalty_Details: 'Royalty Details',
  income: 'Income',
  spending: 'Spending',
  Bind_account: 'Account binding',
  Please_enter_the_transaction_password: 'Please enter the transaction password',
  noSart: 'Not yet open to regular members',
  search: 'Search',
  latest: 'Latest',
  hottest: 'Hottest',
  p_e_search: 'Please enter search keyword',
  Application_Notes: 'Application Notes',
  issuer_desc: 'To apply to become an NFT issuer, you need to have the corresponding legal qualifications for blockchain. For details, please contact official customer service or email',
  start: 'Start',

  Make_NFT_fragments: 'Make NFT Fragments',
  NFTfile: 'NFT File',
  Purchase_limit: 'Purchase Limit',
  Purchase_limit_desc: 'Purchase limit per person',
  Currency: 'Currency',
  Buyback_price: 'Buyback Price',
  countDown: 'Countdown (hours)',
  Transaction_object_ID: 'Transaction Object ID',
  Click_to_select_NFT_file: 'Select NFT File',
  Click_to_select_Currency: 'Select Currency',
  Click_to_select_countDown: 'Select Countdown Time',
  p_e_price: 'Please enter price',
  create_now: 'Create Now',
  Release_record: 'Release Record',
  feeDesc:'Withdrawal handling fee',
  feeDesc2: 'A service fee will be deducted',
}
const nft ={
  "collect": "Collection",
  "transfer": "Transfer NFT",
  "pledgeRecord": "Fragment Pledge Record",
  "hangout": "Listing",
  "buytitle": "I Want to Buy",
  "buy": "Buy",
  "sell": "Sell",
  "selltitle": "I Want to Sell",
  "listcount": "Listing Quantity",
  "owner": "Owner",
  "between": "Range",
  "remains": "Remaining",
  "hangoutlabel": ["Currently Held", "Total Number of NFTs", "Type", "Pre-sale Price", "Price",
    "Quantity", "Limit", "Maximum", "Sell", "Buy"],
  "hangplaceholder": ["Please enter listing price", "Please enter quantity",
    "Please enter limit quantity", "Please enter maximum quantity"],
  holdnumber:'Holding quantity',
  available_number:'Available quantity',
  hangoutType:["Normal Order","Benefit Order","Specified User ID","Please enter the specified user ID"]

}

const otc = {
  orderID: "Order ID",
  amount: "Amount",
  addtime: "Time",
  detailtitle: "Transaction Information",
  logintips: [
    "Your information is protected by 256-bit SGC",
    "Using encryption for secure transmission"
  ],
  detail: ["Price", "Transaction Quantity", "Payment Amount", "Order ID", "Time", "Please contact customer service"]
}

const onekey = {
  royalties: "Royalties",
  hold: "Hold Quantity",
  all: "All",
  selltips: [
    "NFT royalties are a simple and easy way to profit from your hard work.",
    "NFT royalties are a great opportunity for artists, game developers, and content creators to be able to profit from secondary sales in a way they never could before.",
    "You can profit from buying and selling your royalties through TNFT Royalties Trading Express."
  ],
}

const order2 = {
  presale: "Presale",
  otc: "OTC Balance",
  pay: "Pay",
  c2c: "C2C Transfer",
  waitconfirm: "Pending",
  pass: "Approved",
  c2ctip: "C2C requires manual transfer",
  status: ["", "Waiting for Payment", "Waiting for Confirmation", "Completed", "Fail"],
  confirm: ["Confirm Order", "Presale in progress, please complete the order within the specified time and pay the seller"],
  c2cpay: ["Supported Currency", "Address", "Payment Amount", "Upload Proof",'Payment Voucher'],
  position:['Number of holdings',"Please complete the specified transaction amount first",],
  otctip:'OTC-pay will use your balance to pay, please confirm',
  missingfund:'Missing transaction password',
  setfundtip:'You have not set a transaction password yet. Please set a transaction password first.'

}


export {
  tab,
  index,
  find,
  position,
  order,
  login,
  share,
  my,
  nft,
  otc,
  onekey,
  order2
}
